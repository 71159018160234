import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p>{`５つ目の手順まで来ましたね。`}</p>
      <p>{`「75点の法則」でも話しましたが、１つの手順を初めから100点にするのは不可能です。必ず、後との兼ね合いで微妙に修正が必要になることはあります。`}<br parentName="p"></br>{`
`}{`例えば、はじめに「大きさ」を完璧にしたと思っても、後からコンプやEQをかけた影響で、何となく聞こえる大きさが変わることもあります。そうなった場合は、「全部一からやり直しや！」と思う必要はなく、その都度ちょちょっと修正すればOKです。ですから、安心して75点で進んでください。75点で進めて、後で気がついたところをちょこちょこ直すのが原則です。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      